interface StarProps {
  width?: number;
  height?: number;
  stroke?: string;
}

const Star = ({ width = 30, height = 29, stroke = "#CF3916" }: StarProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.98297 7.21349L9.08765 7.17839L9.15515 7.09101L13.2279 1.81828L13.2279 1.8183L13.231 1.81417C13.4892 1.46994 13.7881 1.22652 14.1269 1.07249C14.48 0.911998 14.8469 0.832356 15.231 0.832356C15.6151 0.832356 15.982 0.911998 16.3351 1.07249C16.6739 1.22652 16.9728 1.46994 17.231 1.81417L17.231 1.8142L17.2341 1.81828L21.3069 7.09101L21.3743 7.17839L21.479 7.21349L27.6609 9.28622L27.6608 9.28633L27.6695 9.289C28.2254 9.46005 28.6538 9.77021 28.9691 10.2222C29.2906 10.683 29.4492 11.1862 29.4492 11.7414C29.4492 11.9965 29.4121 12.2531 29.3365 12.5124C29.2643 12.7596 29.1447 13.0009 28.9733 13.2367L28.9732 13.2366L28.9702 13.241L24.9702 18.9137L24.9009 19.012L24.9038 19.1321L25.0493 25.0958L25.0493 25.0973C25.0708 25.8493 24.8279 26.4666 24.3193 26.9752C23.8011 27.4934 23.2131 27.7414 22.5401 27.7414C22.5425 27.7414 22.5387 27.741 22.5267 27.7396C22.5036 27.7369 22.4501 27.7308 22.3529 27.7175C22.226 27.7002 22.0473 27.6743 21.8162 27.6397L15.3288 25.8276L15.231 25.8003L15.1332 25.8276L8.62408 27.6458L8.60513 27.6511L8.58686 27.6584C8.50287 27.692 8.40537 27.7151 8.29171 27.7245C8.15436 27.736 8.03126 27.7414 7.92191 27.7414C7.2489 27.7414 6.66089 27.4934 6.14268 26.9752C5.63409 26.4666 5.39118 25.8493 5.41267 25.0973L5.41271 25.0957L5.55817 19.0957L5.56107 18.9758L5.49209 18.8777L1.52845 13.2414L1.52849 13.2413L1.52509 13.2367C1.35363 13.0009 1.23403 12.7596 1.16191 12.5124C1.08631 12.2531 1.04918 11.9965 1.04918 11.7414C1.04918 11.2107 1.2017 10.7174 1.5143 10.2539C1.81871 9.8025 2.24299 9.47978 2.80291 9.28563L8.98297 7.21349Z"
        fill="url(#paint0_linear_3296_4800)"
        stroke={stroke}
        strokeWidth="0.727273"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3296_4800"
          x1="15.2492"
          y1="0.468719"
          x2="15.2492"
          y2="28.1051"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E88419" />
          <stop offset="1" stopColor="#E84019" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Star;
