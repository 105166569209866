import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { AccessTokenContext } from "../auth/ProtectedComponent";
import useTranslate from "../locale/hooks/useTranslate";
import { formatDateTime } from "../shared/formatters/DateTimeFormatter";
import { convertUtcIsoDateTimeToLocalIsoDateTime } from "../shared/formatters/LocalDateTime";
import { useClaimRewardQuery, useFetchRewardsQuery } from "./service";
import { IRewardStatus } from "./types";
import Star from "../shared/Star";
import { Authorize, Permissions } from "../permissions";

export default function ReachedMilestone() {
  const access_token = useContext(AccessTokenContext);
  const translate = useTranslate();

  const [isModalOpen, setModal] = useState(false);

  const { data: rewards } = useFetchRewardsQuery(
    { access_token, reward_status: IRewardStatus.UNCLAIMED },
    { pollingInterval: 300000 } //5 minutes
  );
  const { data: receivedReward } = useClaimRewardQuery(
    { access_token },
    { skip: !rewards?.rewards.length, pollingInterval: 300000 }
  );

  useEffect(() => {
    if (receivedReward?.claimed_rewards?.length) {
      setModal(true);
    }
  }, [receivedReward]);

  if (!receivedReward?.claimed_rewards?.length) return null;

  return (
    <Authorize require={[Permissions.READ_REWARD]}>
      <Modal isOpen={isModalOpen} onClose={() => setModal(false)} isCentered size="lg" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{translate("loyalty.new_milestone_reached")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap="2" align="center" py="12" textAlign="center">
              <Flex w="32" h="32" align="center" justify="center" bgColor="gray.200" borderRadius="full">
                <Star width={81} height={77} />
              </Flex>
              <Text textStyle="h2">{translate("loyalty.milestone_reached")}</Text>
              <Text>{translate("loyalty.congratulations")}</Text>

              {receivedReward.claimed_rewards.map((reward, index) => (
                <Box bgColor="gray.200" p="4" borderRadius="lg" border="1px dashed" borderColor="gray.400" key={index}>
                  <Text fontWeight="medium">
                    {translate("loyalty.reward_unlocked", {
                      count: String(reward.amount),
                      type: translate(`loyalty.reward_type.${reward.reward_type}`),
                    })}
                  </Text>
                  <Text textStyle="size-sm" color="gray.500">
                    {translate("loyalty.expires_on", {
                      date: formatDateTime(convertUtcIsoDateTimeToLocalIsoDateTime(reward.expire, "YYYY-MM-DD")),
                    })}
                  </Text>
                </Box>
              ))}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button ml="4" onClick={() => setModal(false)}>
              {translate("placeholders.close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Authorize>
  );
}
