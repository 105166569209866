import { Middleware, MiddlewareAPI, isRejectedWithValue } from "@reduxjs/toolkit";
export const checkUnauthorizedRequests: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // isRejectedWithValue Or isRejected
  if (isRejectedWithValue(action)) {
    // console.log(action); // get all data from rejected request
    if (action.payload?.status === 401) {
      const redirectTarget = `${window.location.pathname}${window.location.search}`;
      //   console.log("redirectTarget", redirectTarget);
      window.history.pushState({ redirectTarget }, "", "/login");
      window.location.href = "/login";
    }
  }

  return next(action);
};
