import React, { Suspense } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { useLocation, Routes, Route, Navigate, Outlet } from "react-router-dom";
import i18n from "i18next";
import Cookies from "js-cookie";
import theme from "./theme/chakra";
import { Fonts } from "./theme/fonts";
import { initReactI18next } from "react-i18next";
import { resources } from "./locale";
import { DEFAULT_LOCALE } from "./config";

import Layout from "./shared/Layout";

import LoginPage from "./auth/routes/LoginPage";
import AuthCallbackPage from "./auth/routes/AuthCallbackPage";
import ProtectedComponent from "./auth/ProtectedComponent";
import PasswordResetPage from "./auth/routes/PasswordResetPage";
import AccessManager from "./shared/AccessManager";
import { VerificationStatus } from "./onboarding/types";
import ReachedMilestone from "./loyaltyProgram/ReachedMilestone";
import FeatureGate from "./shared/FeatureGate";
import { FeatureFlagEnum } from "./account/types";

const DashboardPage = React.lazy(() => import("./dashboard/routes/DashboardPage"));
const MyAccountPage = React.lazy(() => import("./account/routes/MyAccountPage"));
const DealPage = React.lazy(() => import("./deals/routes/DealPage"));
const NewDealPage = React.lazy(() => import("./deals/routes/NewDealPage"));
const ServiceDealPage = React.lazy(() => import("./deals/routes/ServiceDealPage"));
const SettledDealPage = React.lazy(() => import("./deals/routes/SettledDealPage"));

const KycApplicationPage = React.lazy(() => import("./kyc/routes/KycApplicationPage"));

const PendingDealsPage = React.lazy(() => import("./deals/routes/PendingDealsPage"));
const ActiveDealsPage = React.lazy(() => import("./deals/routes/ActiveDealsPage"));
const SettledDealsPage = React.lazy(() => import("./deals/routes/SettledDealsPage"));

const TransactionsPage = React.lazy(() => import("./transactions/routes/TransactionsPage"));
const InventoryVehiclePage = React.lazy(() => import("./inventory/routes/InventoryVehiclePage"));
const InventoryPage = React.lazy(() => import("./inventory/routes/InventoryPage"));

const SoldVehiclePage = React.lazy(() => import("./inventory/routes/SoldVehiclePage"));
const SellVehiclePage = React.lazy(() => import("./inventory/routes/SellVehiclePage"));
const NewInventoryVehiclePage = React.lazy(() => import("./inventory/routes/NewInventoryVehiclePage"));

const EditInventoryVehiclePage = React.lazy(() => import("./inventory/routes/EditInventoryVehiclePage"));
const InvoiceDetailsPage = React.lazy(() => import("./invoice/routes/InvoiceDetailsPage"));

const TempUnavailablePage = React.lazy(() => import("./onboarding/routes/TempUnavailablePage"));
const BankIdValidationPage = React.lazy(() => import("./onboarding/routes/BankIdValidationPage"));
const CompanyInformationPage = React.lazy(() => import("./onboarding/routes/CompanyInformationPage"));
const OnboardingPage = React.lazy(() => import("./onboarding/routes/OnboardingPage"));
const LegalEntityRequiredPage = React.lazy(() => import("./onboarding/routes/LegalEntityRequiredPage"));

const RegisterStatusPage = React.lazy(() => import("./onboarding/routes/RegisterStatusPage"));
const Financials = React.lazy(() => import("./deals/subRoutes/Financials"));
const ServicingOwnership = React.lazy(() => import("./deals/subRoutes/Ownership/ServicingOwnership"));

const Vehicle = React.lazy(() => import("./deals/subRoutes/Vehicle"));
const Offering = React.lazy(() => import("./deals/subRoutes/Offering"));
const Contracts = React.lazy(() => import("./deals/subRoutes/Contracts"));
const Payments = React.lazy(() => import("./deals/subRoutes/Payments"));
const OwnershipPage = React.lazy(() => import("./deals/subRoutes/Ownership"));

const AccountInfoPage = React.lazy(() => import("./onboarding/routes/AccountInfoPage"));
const RegisterWithoutBankIdPage = React.lazy(() => import("./onboarding/routes/RegisterWithoutBankIdPage"));

const TermsAgreementPage = React.lazy(() => import("./kyc/routes/TermsAgreementPage"));
const ApplicationPage = React.lazy(() => import("./kyc/routes/ApplicationPage"));
const OnboardingApplicationSubmittedPage = React.lazy(
  () => import("./onboarding/routes/OnboardingApplicationSubmittedPage")
);
const OnboardingApplicationRejectedPage = React.lazy(
  () => import("./onboarding/routes/OnboardingApplicationRejectedPage")
);

const LoansPage = React.lazy(() => import("./loans/routes/LoansPage"));
const CreateLoanPage = React.lazy(() => import("./loans/routes/CreateLoanPage"));
const LoanPage = React.lazy(() => import("./loans/routes/LoanPage"));

const UsersManagementPage = React.lazy(() => import("./usersManagement/routes/UsersManagementPage"));
// const UsersInvitesPages = React.lazy(() => import("./usersManagement/routes/UsersInvitesPages"));
const EditDealerAccountPage = React.lazy(() => import("./dealerAccounts/routes/EditDealerAccountPage"));
const FilesPage = React.lazy(() => import("./files/routes/FilesPage"));
const FilePage = React.lazy(() => import("./files/routes/FilePage"));

const currentLanguage = Cookies.get("lang") || DEFAULT_LOCALE;

i18n.use(initReactI18next).init({
  resources: resources,
  lng: currentLanguage,
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  let location = useLocation();

  return (
    <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { isClosable: true, duration: 5000 } }}>
      <Fonts />

      <Routes location={location}>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/callback" element={<AuthCallbackPage />} />
        <Route path="/logout" element={<Navigate to="/" />} />
        <Route path="/set-password" element={<PasswordResetPage />} />

        <Route
          path="/onboarding"
          element={
            <ProtectedComponent>
              <OnboardingPage />
            </ProtectedComponent>
          }>
          <Route path="account-info" element={<AccountInfoPage />} />
          <Route path="register-without-bank-id" element={<RegisterWithoutBankIdPage />} />
          <Route
            path="register-pending"
            element={<RegisterStatusPage status={VerificationStatus.VERIFICATION_PENDING} />}
          />
          <Route path="register-rejected" element={<RegisterStatusPage status={VerificationStatus.REJECTED} />} />
          <Route path="temp-unavailable" element={<TempUnavailablePage />} />
          <Route path="bank-id" element={<BankIdValidationPage />} />
          <Route path="company-information" element={<CompanyInformationPage />} />
          <Route path="legal-entity-required" element={<LegalEntityRequiredPage />} />
          <Route path="application/:kyc_application_id" element={<ApplicationPage isOnboarding />} />
          <Route path="application-submitted" element={<OnboardingApplicationSubmittedPage />} />
          <Route path="application-rejected" element={<OnboardingApplicationRejectedPage />} />
        </Route>

        <Route
          path="/"
          element={
            <ProtectedComponent>
              <AccessManager>
                <Layout>
                  <Suspense>
                    <Outlet />
                  </Suspense>
                </Layout>

                <FeatureGate features={[FeatureFlagEnum.LOYALTY_PROGRAM]}>
                  <ReachedMilestone />
                </FeatureGate>
              </AccessManager>
            </ProtectedComponent>
          }>
          <Route path="/dashboard" element={<DashboardPage />} />

          <Route path="/dealer-agreement" element={<TermsAgreementPage />} />

          <Route path="/my-account" element={<MyAccountPage />} />

          <Route path="/deals-pending" element={<PendingDealsPage />} />
          <Route path="/deals-active" element={<ActiveDealsPage />} />
          <Route path="/deals-settled" element={<SettledDealsPage />} />
          <Route path="/new-deal" element={<NewDealPage />} />
          <Route path="/deal/:deal_id" element={<DealPage />}>
            <Route index element={<Vehicle />} />
            <Route path="vehicle" element={<Vehicle />} />
            <Route path="offering" element={<Offering />} />
            <Route path="contracts" element={<Contracts />} />
            <Route path="payments" element={<Payments />} />
            <Route path="ownership" element={<OwnershipPage />} />
          </Route>
          <Route path="/servicing/:deal_id" element={<ServiceDealPage />}>
            <Route index element={<Financials />} />
            <Route path="financials" element={<Financials />} />
            <Route path="ownership" element={<ServicingOwnership />} />
          </Route>
          <Route path="/settled/:deal_id" element={<SettledDealPage />} />

          <Route path="kyc/application/:kyc_application_id" element={<KycApplicationPage />} />

          <Route path="transactions" element={<TransactionsPage />} />
          <Route path="/inventory" element={<InventoryPage />} />

          <Route path="/new-inventory-vehicle" element={<NewInventoryVehiclePage />} />
          <Route path="/inventory-vehicle/:inventoryVehicleId/edit" element={<EditInventoryVehiclePage />} />

          <Route path="/inventory-vehicle/:inventoryVehicleId" element={<InventoryVehiclePage />} />

          <Route path="/inventory-sold-vehicle/:inventoryVehicleId" element={<SoldVehiclePage />} />

          <Route path="/inventory-sell-vehicle/:inventoryVehicleId" element={<SellVehiclePage />} />
          <Route path="/invoice/:invoice_id" element={<InvoiceDetailsPage />} />

          <Route path="/loans" element={<LoansPage />} />
          <Route path="/loan/new" element={<CreateLoanPage />} />
          <Route path="/loan/:applicationId" element={<LoanPage />} />

          <Route path="/users" element={<UsersManagementPage />} />
          <Route path="/company-info" element={<EditDealerAccountPage />} />
          <Route path="/files" element={<FilesPage />} />
          <Route path="/file/:fileId" element={<FilePage />} />

          {/* <Route path="/invites" element={<UsersInvitesPages />} /> */}
        </Route>
      </Routes>
    </ChakraProvider>
  );
}

export default App;
