import { useAuth0 } from "@auth0/auth0-react";
import { RUN_LOCALLY, LOCAL_AUTH_URL } from "../../config";
const LoginPage = () => {
  const { loginWithRedirect, logout } = useAuth0();
  if (RUN_LOCALLY) {
    window.location.href = LOCAL_AUTH_URL;
    return null;
  }
  let redirectTo = undefined;
  const { state } = window.history;
  if (state?.redirectTarget) {
    logout();
    redirectTo = { appState: { target: state.redirectTarget } };
  }
  loginWithRedirect(redirectTo);
  return null;
};

export default LoginPage;
