import React from "react";
import DateTimeFormatter from "./DateTimeFormatter";

type FormatFunction = (
  year: number,
  month: string,
  day: string,
  hours: string,
  minutes: string,
  seconds: string
) => string;

type DateFormat = "YYYY-MM-DD" | "YYYY-MM-DD HH:MM" | "YYYY-MM-DD HH:MM:SS";
const dateTimeFormats = new Map<DateFormat, FormatFunction>([
  ["YYYY-MM-DD", (year, month, day) => `${year}-${month}-${day}`],
  ["YYYY-MM-DD HH:MM", (year, month, day, hours, minutes) => `${year}-${month}-${day}T${hours}:${minutes}`],
  [
    "YYYY-MM-DD HH:MM:SS",
    (year, month, day, hours, minutes, seconds) => `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`,
  ],
]);

const convertUTCDateToLocalDate = function (date: Date) {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
  );
};
const convertUtcIsoDateTimeToLocalIsoDateTime = (
  utcIsoDateTime: string | null | Date,
  format: DateFormat = "YYYY-MM-DD HH:MM:SS"
) => {
  if (utcIsoDateTime === null) {
    return "";
  }
  /*Converts and display an UTC ISO Datetime to a local ISO like format */
  const localDatetime = convertUTCDateToLocalDate(new Date(utcIsoDateTime));
  const year = localDatetime.getFullYear();
  const month = (localDatetime.getMonth() + 1).toString().padStart(2, "0");
  const day = localDatetime.getDate().toString().padStart(2, "0");
  const hours = localDatetime.getHours().toString().padStart(2, "0");
  const minutes = localDatetime.getMinutes().toString().padStart(2, "0");
  const seconds = localDatetime.getSeconds().toString().padStart(2, "0");
  //   const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  const formatFunction = dateTimeFormats.get(format);
  const formattedDate = formatFunction ? formatFunction(year, month, day, hours, minutes, seconds) : "";
  return formattedDate;
};

type Props = {
  utcIsoDateTime: string | null;
};
const LocalDateTime = (props: Props) => {
  if (props.utcIsoDateTime === null) {
    return <></>;
  }
  /*Converts and display an UTC ISO Datetime to a local ISO like format */
  const formattedDate = convertUtcIsoDateTimeToLocalIsoDateTime(props.utcIsoDateTime);
  return <>{formattedDate} </>;
};

const FormattedLocalDateTime = (props: Props) => {
  if (props.utcIsoDateTime === null) {
    return <></>;
  }
  /*Converts and display an UTC ISO Datetime to a local ISO like format */
  const formattedDate = convertUtcIsoDateTimeToLocalIsoDateTime(props.utcIsoDateTime);
  return <DateTimeFormatter isodatetime={formattedDate} />;
};

const getRemainingDaysBetweenDates = (
  startDate: string | null,
  endDate: string | null,
  round: "up" | "down" = "up"
): number | null => {
  if (!startDate || !endDate) return null;
  const startDateFormatted = new Date(convertUtcIsoDateTimeToLocalIsoDateTime(startDate));
  const endDateFormatted = new Date(convertUtcIsoDateTimeToLocalIsoDateTime(endDate));
  const timeDifference = startDateFormatted.getTime() - endDateFormatted.getTime();
  if (timeDifference <= 0) return 0;
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  const totalDays = round === "down" ? Math.floor(daysDifference) : Math.ceil(daysDifference);
  return totalDays;
};

export default LocalDateTime;
export {
  convertUTCDateToLocalDate,
  convertUtcIsoDateTimeToLocalIsoDateTime,
  FormattedLocalDateTime,
  getRemainingDaysBetweenDates,
};
