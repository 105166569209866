import React from "react";

type Props = {
  isodatetime: string;
};

const formatDateTime = (isodatetime: string) => {
  return isodatetime.replace("T", " ").replace("Z", "");
};

const formatDate = (isodatetime: string) => {
  return isodatetime.split("T")[0];
};

const DateTimeFormatter = ({ isodatetime }: Props) => {
  return <>{formatDateTime(isodatetime)}</>;
};

const calculateTiming = (initialDays: number) => {
  let months = 0, years = 0, days = 0, weeks = 0;
  while (initialDays) {
    if (initialDays >= 365) {
      years++;
      initialDays -= 365;
    } else if (initialDays >= 30) {
      months++;
      initialDays -= 30;
    } else if (initialDays >= 7) {
      weeks++;
      initialDays -= 7;
    } else {
      days++;
      initialDays--;
    }
  };
  return { years, months, weeks, days };
};

export default DateTimeFormatter;
export { formatDateTime, formatDate, calculateTiming };
