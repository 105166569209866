import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IGetFilesPayload,
  IGetFilesResponse,
  IInitFileUploadPayload,
  IInitFileUploadResponse,
  IFinalizeFileUploadPayload,
  IFinalizeFileUploadResponse,
  ICancelFileUploadPayload,
  ICancelFileUploadResponse,
  IGetFilePayload,
  IFile,
  IDeleteFilePayload,
  IDeleteFileResponse,
  IGetFileLabelsPayload,
  IGetFileLabelsResponse,
  IAddFileLabelPayload,
  IAddFileLabelResponse,
  IDeleteFileLabelPayload,
  IDeleteFileLabelResponse,
  IFetchFilesLabelsResponse,
  IFetchFilesLabelsPayload,
  IGetFilesBatchPayload,
  IFileUploadAuthorizationResponse,
  IFileUploadAuthorizationPayload,
  IExportTransactionReportPayload,
  IExportTransactionReportResponse,
} from "./types";

export const filesApi = createApi({
  reducerPath: "filesApi",
  tagTypes: ["Files", "Labels"],
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    fetchFileUploadAuthorization: builder.mutation<IFileUploadAuthorizationResponse, IFileUploadAuthorizationPayload>({
      query: ({ access_token, ...body }) => {
        return {
          url: "/v1/file_upload_authorization",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    getFilesBatchLookup: builder.mutation<IFile[], IGetFilesBatchPayload>({
      query: ({ access_token, file_ids }) => {
        return {
          url: "/v1/file_batch_lookup",
          method: "POST",
          body: { file_ids },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    getFiles: builder.query<IGetFilesResponse, IGetFilesPayload>({
      query: ({ access_token, ...queryParams }) => {
        return {
          url: "/v1/file",
          method: "GET",
          params: queryParams,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Files"],
    }),
    getFile: builder.query<IFile, IGetFilePayload>({
      query: ({ access_token, file_id, revision_id = null }) => {
        const params: any = {};
        if (revision_id) {
          params["revision_id"] = revision_id;
        }
        return {
          url: `/v1/file/${file_id}`,
          method: "GET",
          params: params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Files"],
    }),
    deleteFile: builder.mutation<IDeleteFileResponse, IDeleteFilePayload>({
      query: ({ access_token, file_id }) => {
        return {
          url: `/v1/file/${file_id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Files"],
    }),
    initFileUpload: builder.mutation<IInitFileUploadResponse, IInitFileUploadPayload>({
      query: ({ access_token, ...body }) => {
        return {
          url: "/v1/file_upload",
          method: "POST",
          body: body,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
    }),
    finalizeFileUpload: builder.mutation<IFinalizeFileUploadResponse, IFinalizeFileUploadPayload>({
      query: ({ access_token, job_id, etags }) => {
        return {
          url: `/v1/file_upload/${job_id}/finalize`,
          method: "POST",
          body: { etags },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Files"],
    }),
    cancelFileUpload: builder.mutation<ICancelFileUploadResponse, ICancelFileUploadPayload>({
      query: ({ access_token, job_id }) => {
        return {
          url: `/v1/file_upload/${job_id}/cancel`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Files"],
    }),
    getFileLabels: builder.query<IGetFileLabelsResponse, IGetFileLabelsPayload>({
      query: ({ access_token, file_id }) => {
        return {
          url: `/v1/file/${file_id}/label`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Labels"],
    }),
    addFileLabel: builder.mutation<IAddFileLabelResponse, IAddFileLabelPayload>({
      query: ({ access_token, file_id, label }) => {
        return {
          url: `/v1/file/${file_id}/label`,
          method: "PUT",
          body: { label },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Labels", "Files"],
    }),
    deleteFileLabel: builder.mutation<IDeleteFileLabelResponse, IDeleteFileLabelPayload>({
      query: ({ access_token, file_id, label }) => {
        return {
          url: `/v1/file/${file_id}/label`,
          method: "DELETE",
          body: { label },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Labels", "Files"],
    }),
    getFilesLabels: builder.mutation<IFetchFilesLabelsResponse, IFetchFilesLabelsPayload>({
      query: ({ access_token, file_ids }) => {
        return {
          url: "/v1/file_label_batch_lookup",
          method: "POST",
          body: { file_ids },
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Files"],
    }),
    exportTransactionReport: builder.mutation<IExportTransactionReportResponse, IExportTransactionReportPayload>({
      query: ({ access_token, deal_id }) => {
        return {
          url: `/v1/deal/${deal_id}/transaction_report`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Files"],
    }),
  }),
});

export const {
  useFetchFileUploadAuthorizationMutation,
  useGetFilesBatchLookupMutation,
  useGetFilesQuery,
  useGetFileQuery,
  useDeleteFileMutation,
  useInitFileUploadMutation,
  useFinalizeFileUploadMutation,
  useCancelFileUploadMutation,
  useGetFileLabelsQuery,
  useAddFileLabelMutation,
  useDeleteFileLabelMutation,
  useGetFilesLabelsMutation,
  useExportTransactionReportMutation,
} = filesApi;
