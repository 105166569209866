import { IAuthParams } from "../auth/types";

export enum ILoyaltyPrograms {
  SETTLED_BONUS = "settled_bonus",
}

export enum IRewardStatus {
  CLAIMED = "claimed",
  UNCLAIMED = "unclaimed",
}

enum IRewardType {
  SETUP_FEE_VOUCHER = "setup_fee_voucher",
}

interface ILoyaltyProgram {
  enrollment_id: string;
  points: number;
  loyalty_program: ILoyaltyPrograms;
}
interface IMilestone {
  reached: string | null; //timestamp
  required_points: number;
}
interface ILoyaltyReward {
  reward_id: string;
  reward_status: IRewardStatus;
  reward_type: IRewardType;
  loyalty_program: ILoyaltyPrograms;
  claimed_by: string;
  claimed_at?: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}
interface IListLoyaltyProgramPayload extends IAuthParams {
  offset?: number;
  limit?: number;
}

interface IListLoyaltyProgramResponse {
  loyalty_program: ILoyaltyProgram[];
}

interface IFetchMilestonesPayload extends IAuthParams {
  enrollment_id: string;
  loyalty_program: ILoyaltyPrograms;
}

interface IFetchMilestonesResponse {
  enrollment_id: string;
  achieved_points: number;
  milestones: IMilestone[];
}

interface IFetchRewardsPayload extends IAuthParams {
  offset?: number;
  limit?: number;
  reward_status?: IRewardStatus;
}

interface IFetchRewardsResponse {
  rewards: ILoyaltyReward[];
  total_count: number;
  offset: number;
  limit: number;
}
interface IFetchNextMilestoneRewardPayload extends IAuthParams {
  loyalty_program: ILoyaltyPrograms;
}

type IFetchNextMilestoneRewardResponse = Record<IRewardType, number>;

interface IClaimRewardPayload extends IAuthParams {}

interface IClaimedReward {
  reward_type: IRewardType;
  amount: number;
  expire: string;
}
interface IClaimRewardResponse {
  claimed_rewards: IClaimedReward[];
}

export type {
  IClaimRewardPayload,
  IClaimRewardResponse,
  IFetchNextMilestoneRewardResponse,
  IFetchNextMilestoneRewardPayload,
  IMilestone,
  ILoyaltyReward,
  IFetchRewardsPayload,
  IFetchRewardsResponse,
  IListLoyaltyProgramPayload,
  IListLoyaltyProgramResponse,
  ILoyaltyProgram,
  IFetchMilestonesPayload,
  IFetchMilestonesResponse,
};
