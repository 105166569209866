import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";

import {
  IFetchVouchersPayload,
  IFetchVouchersResponse,
  IReleaseVoucherPayload,
  IReleaseVoucherResponse,
  IReserveVoucherPayload,
  IReserveVoucherResponse,
} from "./types";

export const voucherApi = createApi({
  reducerPath: "voucherApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["Vouchers"],
  endpoints: (builder) => ({
    fetchVouchers: builder.query<IFetchVouchersResponse, IFetchVouchersPayload>({
      query: ({ access_token, ...params }) => {
        return {
          url: "/v1/voucher",
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["Vouchers"],
    }),
    reserveVoucher: builder.mutation<IReserveVoucherResponse, IReserveVoucherPayload>({
      query: ({ access_token, voucher_id, ...payload }) => {
        return {
          url: `/v1/voucher/${voucher_id}/reserve`,
          method: "PUT",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Vouchers"],
    }),
    releaseVoucher: builder.mutation<IReleaseVoucherResponse, IReleaseVoucherPayload>({
      query: ({ access_token, voucher_id, ...payload }) => {
        return {
          url: `/v1/voucher/${voucher_id}/release`,
          method: "PUT",
          body: payload,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      invalidatesTags: ["Vouchers"],
    }),
  }),
});

export const { useFetchVouchersQuery, useReserveVoucherMutation, useReleaseVoucherMutation } = voucherApi;
