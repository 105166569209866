const disabledStyles = {
  solid: {
    bg: "grayLight.500",
    color: "grayMid.500",
    cursor: "not-allowed",
    opacity: "1",
    _hover: {
      bg: "grayLight.500 !important",
      color: "grayMid.500",
      cursor: "not-allowed",
      opacity: "1",
    },
  },
  outline: {
    color: "grayMid.500",
    borderColor: "grayRegular.500",
    _hover: {
      color: "grayMid.500",
      borderColor: "grayRegular.500",
    },
  },
  ghost: {
    color: "grayMid.500",
    _hover: {
      color: "grayMid.500",
      textDecoration: "none",
    },
  },

  iconOption: {
    color: "gray.500",
    bg: "disabled.500",
    borderColor: "gray.300",

    _hover: {
      color: "gray.500",
      bg: "disabled.500",
      borderColor: "gray.300",
    },
  },
};

const iconOptionBase = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "2",
  px: "4",
  py: "2",
  width: "auto",
  height: "auto",
  border: "1px",
  borderRadius: "none",
  color: "gray.600",

  _active: {
    bg: "purple.200",
    borderColor: "purple.800",
  },
};

const button = {
  baseStyle: {
    borderRadius: "100px",
  },
  sizes: {
    md: {
      height: "40px",
    },
  },
  variants: {
    solid: {
      padding: "0 24px",
      bg: "orange.700",
      color: "#fff",
      _hover: {
        bg: "orange.800",
      },
      _active: {
        bg: "orange.900",
      },
      _disabled: { ...disabledStyles.solid },
    },
    solidAlt: {
      padding: "0 24px",
      bg: "orange.200",
      color: "orange.700",
      _hover: {
        bg: "orange.300",
      },
      _active: {
        bg: "orange.400",
      },
      _disabled: { ...disabledStyles.solid },
    },
    secondary: {
      padding: "0 24px",
      bg: "purple.800",
      color: "#fff",
      _hover: {
        bg: "purple.700",
      },
      _active: {
        bg: "purple.600",
      },
      _disabled: { ...disabledStyles.solid },
    },
    secondaryAlt: {
      padding: "0 24px",
      bg: "purple.100",
      color: "purple.800",
      _hover: {
        bg: "purple.300",
      },
      _active: {
        bg: "purple.400",
      },
      _disabled: { ...disabledStyles.solid },
    },
    additional: {
      padding: "0 24px",
      bg: "greens.700",
      color: "#fff",
      _hover: {
        bg: "greens.800",
      },
      _active: {
        bg: "greens.900",
      },
      _disabled: { ...disabledStyles.solid },
    },
    additionalAlt: {
      padding: "0 24px",
      bg: "reds.700",
      color: "#fff",
      _hover: {
        bg: "reds.800",
      },
      _active: {
        bg: "reds.900",
      },
      _disabled: { ...disabledStyles.solid },
    },
    outline: {
      color: "orange.700",
      borderColor: "orange.700",
      _hover: {
        color: "orange.800",
        borderColor: "orange.800",
        bg: "rgba(207,57,22,.08)",
      },
      _active: {
        color: "orange.900",
        borderColor: "orange.900",
        bg: "rgba(181,50,20,.08)",
      },
      _disabled: { ...disabledStyles.outline },
    },
    outlineSecondary: {
      color: "purple.800",
      border: "1px",
      borderColor: "purple.800",
      _hover: {
        color: "purple.700",
        borderColor: "purple.700",
        bg: "rgba(94, 17, 69, 0.08)",
      },
      _active: {
        color: "purple.600",
        borderColor: "purple.600",
        bg: "rgba(120, 21, 88, 0.08)",
      },
      _disabled: { ...disabledStyles.outline },
    },
    outlineWhite: {
      color: "white",
      border: "1px",
      borderColor: "white",
      _hover: {
        color: "gray.300",
        borderColor: "gray.300",
        bg: "rgba(235, 235, 235, 0.08)",
      },
      _active: {
        color: "gray.400",
        borderColor: "gray.400",
        bg: "rgba(197, 197, 197, 0.08)",
      },
      _disabled: { ...disabledStyles.outline },
    },
    outlineAdditionalAlt: {
      color: "reds.700",
      border: "1px",
      borderColor: "reds.700",
      _hover: {
        color: "reds.800",
        borderColor: "reds.800",
        bg: "rgba(214, 188, 218, 0.08)",
      },
      _active: {
        color: "reds.900",
        borderColor: "reds.900",
        bg: "rgba(214, 188, 218, 0.08)",
      },
      _disabled: { ...disabledStyles.outline },
    },
    ghost: {
      color: "orange.700",
      _hover: {
        color: "orange.800",
        bg: "rgba(207,57,22,.08)",
      },
      _active: {
        color: "orange.900",
        bg: "rgba(181,50,20,.08)",
      },
      _disabled: { ...disabledStyles.ghost },
    },
    ghostSecondary: {
      color: "purple.800",
      _hover: {
        color: "purple.700",
        bg: "rgba(94, 17, 69, 0.08)",
      },
      _active: {
        color: "purple.600",
        bg: "rgba(120, 21, 88, 0.08)",
      },
      _disabled: { ...disabledStyles.ghost },
    },
    ghostAdditionalAlt: {
      color: "reds.700",
      _hover: {
        color: "reds.800",
        bg: "rgba(214, 188, 218, 0.1)",
      },
      _active: {
        color: "reds.900",
        bg: "rgba(214, 188, 218, 0.1)",
      },
      _disabled: { ...disabledStyles.ghost },
    },
    link: {
      height: "auto",
      p: "0",
      color: "orange.700",
      _hover: {
        color: "orange.800",
        textDecoration: "none",
      },
      _active: {
        color: "orange.900",
      },
      _disabled: { ...disabledStyles.ghost },
    },
    linkSecondary: {
      height: "auto",
      p: "0",
      color: "purple.800",
      _hover: {
        color: "purple.700",
      },
      _active: {
        color: "purple.600",
      },
      _disabled: { ...disabledStyles.ghost },
    },
    linkWhite: {
      height: "auto",
      p: "0",
      color: "white",
      _hover: {
        color: "gray.300",
      },
      _active: {
        color: "gray.400",
      },
      _disabled: { ...disabledStyles.ghost },
    },
    filterButton: {
      padding: 0,
      borderRadius: "0",
      color: "grayRegular.500",
      border: "1px",
      borderColor: "transparent",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      _hover: {
        background: "#fff",
        color: "purple.800",
        borderColor: "purple.500",
      },
      _active: {
        background: "#fff",
        color: "purple.800",
        borderColor: "purple.500",
      },
    },
    filterButtonAlt: {
      padding: "8px 12px",
      borderRadius: "0",
      border: "1px",
      borderColor: "gray.300",
      color: "gray.600",
      background: "#fff",
      fontWeight: "normal",
      alignItems: "center",
      _hover: {
        borderColor: "purple.500",
      },
      _active: {
        borderColor: "purple.500",
      },
      _disabled: {
        color: "gray.500",
        background: "rgba(197, 197, 197, 0.1)",
        _hover: {
          color: "gray.500",
          borderColor: "gray.300",
        },
      },
    },
    paginationOutline: {
      p: 0,
      fontWeight: "normal",
      border: "1px",
      borderColor: "grayLight.500",
      bg: "#fff",
      borderRadius: "0",
      _hover: {
        bg: "grayLight.500",
      },
    },
    paginationSolid: {
      p: 0,
      fontWeight: "normal",
      border: "1px",
      borderColor: "purple.800",
      bg: "purple.800",
      color: "#fff",
      borderRadius: "0",
    },
    backButton: {
      fontWeight: "normal",
      padding: "0",
      border: "1px",
      borderColor: "grayLight.500",
      background: "#fff",
      _hover: {
        background: "transparent",
        borderColor: "grayMid.500",
      },
    },
    backButtonAlt: {
      fontWeight: "normal",
      padding: "0",
      border: "0",
      background: "gray.200",
      _hover: {
        background: "gray.300",
      },
    },
    userAccount: {
      fontWeight: "normal",
      padding: "0",
      borderRadius: "0",
      bg: "transparent",
    },
    iconOption: {
      ...iconOptionBase,
      bg: "white",
      borderColor: "gray.300",
      fontWeight: "light",
      _hover: {
        borderColor: "purple.500",
      },
      _disabled: { ...disabledStyles.iconOption },
    },
    iconOptionActive: {
      ...iconOptionBase,
      bg: "purple.200",
      borderColor: "purple.800",
      fontWeight: "medium",
      cursor: "default",
      _disabled: { ...disabledStyles.iconOption },
    },
    blankButton: {
      p: "0",
      border: "none",
      bg: "transparent",
      height: "auto",
      minW: "auto",
    },
  },
};

export default button;
