import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL } from "../config";
import {
  IClaimRewardPayload,
  IClaimRewardResponse,
  IFetchMilestonesPayload,
  IFetchMilestonesResponse,
  IFetchNextMilestoneRewardPayload,
  IFetchNextMilestoneRewardResponse,
  IFetchRewardsPayload,
  IFetchRewardsResponse,
  IListLoyaltyProgramPayload,
  IListLoyaltyProgramResponse,
} from "./types";

export const loyaltyProgramApi = createApi({
  reducerPath: "loyaltyProgramApi",
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ["LoyaltyPrograms", "LoyaltyRewards"],
  endpoints: builder => ({
    fetchLoyaltyProgram: builder.query<IListLoyaltyProgramResponse, IListLoyaltyProgramPayload>({
      query: ({ access_token, ...params }) => {
        return {
          url: "/v1/loyalty_program",
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),
    fetchMilestones: builder.query<IFetchMilestonesResponse, IFetchMilestonesPayload>({
      query: ({ access_token, enrollment_id, ...params }) => {
        return {
          url: `/v1/loyalty_program/${enrollment_id}/milestones`,
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["LoyaltyPrograms"],
    }),
    fetchRewards: builder.query<IFetchRewardsResponse, IFetchRewardsPayload>({
      query: ({ access_token, ...params }) => {
        return {
          url: `/v1/reward`,
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["LoyaltyPrograms", "LoyaltyRewards"],
    }),
    fetchNextMilestoneReward: builder.query<IFetchNextMilestoneRewardResponse, IFetchNextMilestoneRewardPayload>({
      query: ({ access_token, ...params }) => {
        return {
          url: `/v1/next_milestone_reward`,
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["LoyaltyPrograms", "LoyaltyRewards"],
    }),
    claimReward: builder.query<IClaimRewardResponse, IClaimRewardPayload>({
      query: ({ access_token, ...params }) => {
        return {
          url: `/v1/claim_reward`,
          method: "GET",
          params,
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        };
      },
      providesTags: ["LoyaltyPrograms", "LoyaltyRewards"],
    }),
  }),
});

export const {
  useFetchLoyaltyProgramQuery,
  useFetchMilestonesQuery,
  useFetchRewardsQuery,
  useFetchNextMilestoneRewardQuery,
  useClaimRewardQuery,
} = loyaltyProgramApi;
